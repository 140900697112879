import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import logoSm from "../../assets/icons/logo-1.jpg";
import { ReadCountriesService } from "../../services/general";
import { ReadLoyaltyTransferPointsService, ValidateLoyaltyTransferPointsService } from "../../services/loyalty";
import "./qrscan.css";

const QRScan = () => {
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        mobile: "",
        countryCode: ""
    });
    const [formErrors, setFormErrors] = useState({
        mobile: "",
        countryCode: "",
        otherError: ""
    });
    const [qrCodeDetail, setQRCodeDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobile" && value.startsWith("0")) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const countryCodeError = formData.countryCode ? "" : "Country Code cannot be Blank";
        const mobileError = formData.mobile ? "" : "Mobile cannot be Blank";
        setFormErrors({
            countryCode: countryCodeError,
            mobile: mobileError
        });

        if (!countryCodeError && !mobileError) {
            setLoading(true);
            let mobile = '+' + formData.countryCode.split('_')[0] + formData.mobile;
            let temp_validate_response = await ValidateLoyaltyTransferPointsService(params.transfer_history_token, formData.countryCode.split('_')[1], mobile, false);
            if (temp_validate_response.success) {
                toast.success('Loyalty Points Transferred Successfully');
                toast.success('تم تحويل نقاط الولاء بنجاح.');
                navigate("/");
            } else {
                if (temp_validate_response.data.already_validated) {
                    if (temp_validate_response.data.status === 'failed'){
                        setFormErrors((prev) => ({ ...prev, otherError: "QR is already scanned, and it's failed due to some reasons, please contact the sender" }));
                    }else if (temp_validate_response.data.status === 'done'){
                        setFormErrors((prev) => ({ ...prev, otherError: "QR is already scanned, and it's already transferred" }));
                    }
                } else if (temp_validate_response.data.customer_not_same) {
                    setFormErrors((prev) => ({ ...prev, otherError: "Mobile number you have entered is not as per the given by the sender" }));
                } else if (temp_validate_response.data.customer_not_exists) {
                    let is_confirmed = window.confirm("You are not registered as a customer in a loyalty system, do you want to register?");
                    if (is_confirmed){
                        let temp_validate_response = await ValidateLoyaltyTransferPointsService(params.transfer_history_token, formData.countryCode.split('_')[1], mobile, true);
                        if (temp_validate_response.success) {
                            toast.success('Loyalty Points Transferred Successfully');
                            toast.success('تم تحويل نقاط الولاء بنجاح.');
                            navigate("/");
                        } else {
                            if (temp_validate_response.data.already_validated) {
                                if (temp_validate_response.data.status === 'failed'){
                                    setFormErrors((prev) => ({ ...prev, otherError: "QR is already scanned, and it's failed due to some reasons, please contact the sender" }));
                                }else if (temp_validate_response.data.status === 'done'){
                                    setFormErrors((prev) => ({ ...prev, otherError: "QR is already scanned, and it's already transferred" }));
                                }
                            }
                        }
                    }else{
                        navigate("/");
                    }
                }
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            let temp_countries = await ReadCountriesService();
            if (temp_countries) {
                setCountries(temp_countries);
            }
            const fetchQRCodeDetail = async () => {
                const response = await ReadLoyaltyTransferPointsService('', params.transfer_history_token);
                if (response.histories) {
                    setQRCodeDetail(response.histories[0]);
                }
            };
            fetchQRCodeDetail();
            setLoading(false);
        })();
    }, [params.transfer_history_token]);

    return (
        <div className="qr-container-main">
            <div className="wrapper-side form-container">
                <div className="form-container">
                    <div className="logo-wrapper">
                        <img src={logoSm} alt="" />
                    </div>
                    {qrCodeDetail && qrCodeDetail.status === 'pending' ? (
                        <>
                            <h1 className="qr-title">Rewards sent from ********{qrCodeDetail.sender_mobile.slice(-2)}</h1>
                            <form onSubmit={handleSubmit} className="qr-form">
                                <div className="form-group-dx">
                                    <label htmlFor="mobile">Please enter your valid mobile to get your rewards.</label>
                                    <div className="form-group-country-mobile">
                                        <div className="form-group-country">
                                            <select id="countryCode" name="countryCode" required onChange={handleInputChange}>
                                                <option>Select Country</option>
                                                {countries.map((country) => {
                                                    return (
                                                        <option key={country.id} value={country.phone_code + '_' + country.code}>
                                                            +{country.phone_code} - {country.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group-mobile">
                                            <input
                                                type="tel"
                                                id="mobile"
                                                name="mobile"
                                                value={formData.mobile}
                                                placeholder="Enter mobile number"
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {formErrors.countryCode && (
                                    <p className="dx-lib input-error">{formErrors.countryCode}</p>
                                )}
                                {formErrors.mobile && (
                                    <p className="dx-lib input-error">{formErrors.mobile}</p>
                                )}
                                {formErrors.otherError && (
                                    <p className="dx-lib input-error">{formErrors.otherError}</p>
                                )}
                                <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                                    {loading ? "Getting Rewards..." : "Get Rewards"}
                                </button>
                            </form>
                        </>
                    ) : (
                        <div className="form-group-dx">
                            <h1 class="invalid-msg">
                                Sorry, QR Code is already processed or invalid.
                                <br/>
                                عذرًا، تم إستخدام رمز الاستجابة السريعة مسبقًا أو أنه غير صالح.
                            </h1>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QRScan;